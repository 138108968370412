import React from 'react';
import { Link } from "react-router-dom";
import web_development from "../assets/img/web_development.jpg";
import team1 from "../assets/img/team1.jpg";
import team2 from "../assets/img/team2.jpg";
import team3 from "../assets/img/team3.jpg";
import bg_image_1 from "../assets/img/bg_image_1.png";
import bg_image_2 from "../assets/img/bg_image_2.png";
import bg_image_3 from "../assets/img/bg_image_3.png";
import testi_image from "../assets/img/testi_image.png";
import person_1 from "../assets/img/person/person_1.jpg";
import person_2 from "../assets/img/person/person_2.jpg";
import person_3 from "../assets/img/person/person_3.jpg";
import airbnb from "../assets/img/clients/airbnb.png";
import google from "../assets/img/clients/google.png";
import mailchimp from "../assets/img/clients/mailchimp.png";
import paypal from "../assets/img/clients/paypal.png";
import stripe from "../assets/img/clients/stripe.png";
import blog1 from "../assets/img/blog/blog-1.jpg";
import blog2 from "../assets/img/blog/blog-2.jpg";
import blog3 from "../assets/img/blog/blog-3.jpg";
import Header from './Header';
import Footer from './Footer';


const About = () => {
  return (
    <div>
      <Header page="About Us" />
      <main>
        <div className="page-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 py-3">
                <div className="img-fluid text-center">
                  <img src={team1} alt="" />
                </div>
                <br />
                <div className="img-fluid text-center">
                  <img src={team2} alt="" />
                </div>
                <br />
                <div className="img-fluid text-center">
                  <img src={team3} alt="" />
                </div>
              </div>
              <div className="col-lg-6 py-3 pr-lg-5">
                <h2 className="title-section">We're <span className="marked">Dynamic</span> Team of Creatives People</h2>
                <div className="divider" />
                <p>At Gaudtech, we are a team of passionate professionals dedicated to providing high-quality digital solutions to our clients. Our mission is to help businesses achieve their goals and reach their full potential through our expertise in web development, app development, SEO, digital marketing, Facebook ads, Google ads, and more.</p>
                <p>Our team is made up of experienced professionals with a proven track record of delivering successful projects for our clients. We believe in staying ahead of the curve when it comes to technology and innovation, and we are committed to providing our clients with the best solutions and strategies to help them succeed in their respective industries.</p>
                <p>Our services are customized to meet the specific needs of each client, and we work closely with them to understand their goals and challenges. We believe in transparency, open communication, and collaboration to ensure that we deliver the best results possible.</p>
                <p>At Gaudtech, we take pride in our commitment to quality and client satisfaction. We strive to deliver projects on time and within budget, and we are always available to provide ongoing support and service to our clients.</p>
                <p>Whether you are a small business or a large enterprise, we are here to help you achieve your digital goals. Get in touch with us today to learn more about our services and how we can help your business grow and succeed.</p>
                {/* <a href="#" className="btn btn-primary">More Details</a> */}
                {/* <a href="#" className="btn btn-outline border ml-2">Success Stories</a> */}
              </div>
            </div>
          </div> {/* .container */}
        </div> {/* .page-section */}
        {/* <div className="page-section counter-section">
          <div className="container">
            <div className="row align-items-center text-center">
              <div className="col-lg-4">
                <p>Total Invest</p>
                <h2>$<span className="number" data-number={816278} /></h2>
              </div>
              <div className="col-lg-4">
                <p>Yearly Revenue</p>
                <h2>$<span className="number" data-number={216422} /></h2>
              </div>
              <div className="col-lg-4">
                <p>Growth Ration</p>
                <h2><span className="number" data-number={73} />%</h2>
              </div>
            </div>
          </div>  */}
        {/* .container */}
        {/* </div>  */}
        {/* .page-section */}
        {/* Testimonials */}
        <div className="page-section bg-light">
          <div className="container">
            <div className="owl-carousel" id="testimonials">
              <div className="item">
                <div className="row align-items-center">
                  <div className="col-md-6 py-3">
                    <div className="testi-image">
                      <img src={web_development} alt="" />
                    </div>
                  </div>
                  <div className="col-md-6 py-3">
                    <div className="testi-content">
                      <p>Necessitatibus ipsum magni accusantium consequatur delectus a repudiandae nemo quisquam dolorum itaque, tenetur, esse optio eveniet beatae explicabo sapiente quo.</p>
                      <div className="entry-footer">
                        <strong>Melvin Platje</strong> — <span className="text-grey">CEO Slurin Group</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="row align-items-center">
                  <div className="col-md-6 py-3">
                    <div className="testi-image">
                      <img src={person_2} alt="" />
                    </div>
                  </div>
                  <div className="col-md-6 py-3">
                    <div className="testi-content">
                      <p>Repudiandae vero assumenda sequi labore ipsum eos ducimus provident a nam vitae et, dolorum temporibus inventore quaerat consectetur quos! Animi, qui ratione?</p>
                      <div className="entry-footer">
                        <strong>George Burke</strong> — <span className="text-grey">CEO Letro</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> {/* .container */}
        </div> {/* .page-section */}
      </main>
      <Footer />
    </div>
  );
}
export default About;