import React, { useState } from 'react';
// import { fireDb } from '../firebase'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ref, push, child, update } from "firebase/database";
import { json, Link } from "react-router-dom";
import web_development from "../assets/img/web_development.jpg";
import bg_image_1 from "../assets/img/bg_image_1.png";
import bg_image_2 from "../assets/img/bg_image_2.png";
import bg_image_3 from "../assets/img/bg_image_3.png";
import testi_image from "../assets/img/testi_image.png";
import person_1 from "../assets/img/person/person_1.jpg";
import person_2 from "../assets/img/person/person_2.jpg";
import person_3 from "../assets/img/person/person_3.jpg";
import airbnb from "../assets/img/clients/airbnb.png";
import google from "../assets/img/clients/google.png";
import mailchimp from "../assets/img/clients/mailchimp.png";
import paypal from "../assets/img/clients/paypal.png";
import stripe from "../assets/img/clients/stripe.png";
import blog1 from "../assets/img/blog/blog-1.jpg";
import blog2 from "../assets/img/blog/blog-2.jpg";
import blog3 from "../assets/img/blog/blog-3.jpg";
import Header from './Header';
import Footer from './Footer';

const Contact = () => {

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    message: ""
  });

  // const [name, email, phone, message] = userData;
  // const [email, setEmail] = useState('');
  // const [phone, setPhone] = useState('');
  // const [message, setMessage] = useState('');

  const handleInputChange = (e) => {
    let name  = e.target.name;
    let value  = e.target.value;
    setUserData({ ...userData, [name]: value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const response = await fetch('http://localhost:3001/add-query', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ name, email, phone,message })
    // });
    // const data = await response.json();
    // console.log(data);

    // if(data.status=='success'){
    //   alert(data.message);
    //   setName("");
    //   setEmail("");
    //   setPhone("");
    //   setMessage("");

    // }

    if (!userData.name || !userData.email || !userData.phone || !userData.message) {
      toast.error("Please provide input in each input field");
    }
    else {
        const {name, email, phone, message} = userData;
      const res = fetch("https://gaud-tech-solutions-default-rtdb.firebaseio.com/userDataRecords.json", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({name,email,phone,message}),
      }
      );

      // fireDb.child("contacts").push(state);
      setUserData({ name: "", email: "", phone: "", message: "" })
      toast.success("Form Submitted Successfully");
    }
  };
  return (
    <div>
      <Header page="Contact Us" />
      <main>
        <div className="page-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 py-3">
                <h2 className="title-section">Get in Touch</h2>
                <div className="divider" />
                <p>We'd love to hear from you! If you have any questions, comments, or inquiries, please don't hesitate to contact us. Here are the different ways you can get in touch with us:</p>
                <ul className="contact-list">
                  <li>

                    <div className="icon"><span className="mai-map" /></div>
                    <div className="content">Namdev Plaza, Badlapur, Thane, Maharashtra - 421503 </div>
                  </li>
                  <li>
                    <div className="icon"><span className="mai-mail" /></div>
                    <div className="content"><a href="#">info@gaudtech.com</a></div>
                  </li>
                  <li>
                    <div className="icon"><span className="mai-phone-portrait" /></div>
                    <div className="content"><a href="#">+91 9137387575</a></div>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 py-3">
                <div className="subhead">Contact Us</div>
                <h2 className="title-section">Drop Us a Line</h2>
                <div className="divider" />
                <ToastContainer position='top-center'/>
                <form action="#" onSubmit={handleSubmit}>
                  <div className="py-2">
                    <input type="text" className="form-control" name="name" placeholder="Full name" value={userData.name} onChange={handleInputChange} />
                  </div>
                  <div className="py-2">
                    <input type="number" className="form-control" name="phone" placeholder="Mobile" value={userData.phone} onChange={handleInputChange} />
                  </div>
                  <div className="py-2">
                    <input type="email" className="form-control" name="email" placeholder="Email" value={userData.email} onChange={handleInputChange} />
                  </div>
                  <div className="py-2">
                    <textarea rows={6} className="form-control" name="message" placeholder="Enter message" defaultValue={""} value={userData.message} onChange={handleInputChange} />
                  </div>
                  <button type="submit" className="btn btn-primary rounded-pill mt-4">Send Message</button>
                </form>
              </div>
            </div>
          </div> {/* .container */}
        </div> {/* .page-section */}
      </main>
      <Footer />
    </div>
  );
}
export default Contact;