import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import ContactForm from './pages/ContactForm';
import IndexPage from './pages/IndexPage';
import About from './pages/About';
// import ContactForm from './pages/ContactForm';
// import Layout from "./pages/Layout";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import "./assets/vendor/animate/animate.css";
import "./assets/css/bootstrap.css";
import "./assets/css/maicons.css";
import "./assets/vendor/owl-carousel/css/owl.carousel.css";
import "./assets/css/theme.css";
import BlogSingle from './pages/BlogSingle';
import PrivacyPolicy from './pages/PrivacyPolicy';


{/* <link rel="stylesheet" href="../assets/vendor/animate/animate.css" />
<link rel="stylesheet" href="../assets/css/bootstrap.css" />
<link rel="stylesheet" href="../assets/css/maicons.css" />
<link rel="stylesheet" href="../assets/vendor/owl-carousel/css/owl.carousel.css" />
<link rel="stylesheet" href="../assets/css/theme.css" /> */}


// function App() {

//   return (
//     <div className="App">
//       {/* <header className="App-header"> */}
//         {/* <img src={logo} className="App-logo" alt="logo" /> */}
//         {/* <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a> */}

//         <ContactForm />
//       {/* </header> */}
//     </div>
//   );
// }

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Layout />}> */}
          <Route index element={<IndexPage />} />
          {/* <Route path="blog" element={<Blog />} /> */}
          {/* <Route path="blogsingle" element={<BlogSingle />} /> */}
          <Route path="about" element={<About />} />
          <Route path="services" element={<Services />} />
          <Route path="contact" element={<Contact />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          {/* <Route path="*" element={<NoPage />} /> */}
        {/* </Route> */}
      </Routes>
    </BrowserRouter>
  )
}

export default App;
