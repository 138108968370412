import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div>
            <h1>Privacy Policy</h1>
            <p>
                We value your privacy and are committed to protecting it. This privacy policy explains what personal information we collect, how we use it, and how we protect it.
            </p>
            <h2>Personal Information We Collect</h2>
            <p>
                We collect personal information that you voluntarily provide to us when you interact with our React JS page. This information may include your name, email address, phone number, and any other information you choose to provide.
            </p>
            <p>
                We also automatically collect certain information when you visit our page, such as your IP address, browser type, and operating system. This information helps us improve our page and track visitor trends.
            </p>
            <h2>How We Use Your Personal Information</h2>
            <p>
                We may use your personal information to:
            </p>
            <ul>
                <li>Respond to your inquiries and provide customer support</li>
                <li>Send you newsletters, promotional emails, or other marketing materials</li>
                <li>Analyze page usage and improve our services</li>
                <li>Comply with legal obligations</li>
            </ul>
            <p>
                We will never sell or rent your personal information to third parties. However, we may share your information with third-party service providers who assist us in providing our services, such as website hosting, email marketing, or analytics.
            </p>
            <h2>Your Rights and Choices</h2>
            <p>
                You have the right to access, correct, or delete your personal information that we have collected. You can also unsubscribe from our marketing emails at any time by clicking the "unsubscribe" link at the bottom of the email.
            </p>
            <h2>Data Security</h2>
            <p>
                We take reasonable steps to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, please note that no method of transmission over the Internet or electronic storage is 100% secure.
            </p>
            <h2>Changes to this Privacy Policy</h2>
            <p>
                We reserve the right to update this privacy policy at any time. Any changes will be posted on this page, and we encourage you to review this policy periodically.
            </p>
            <h2>Contact Us</h2>
            <p>
                If you have any questions or concerns about this privacy policy or our data practices, please contact us at support@gaudtech.com.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
