import React from 'react';
import { Link } from "react-router-dom";

import About from './About';
import Blog from "./Blog";

import web_development from "../assets/img/web_development.jpg";
import digital_marketing from "../assets/img/digital_marketing.jpg";
import app_development from "../assets/img/app_development.jpg";
import facebook_ads from "../assets/img/facebook_ads.jpg";
import google_ads from "../assets/img/google_ads.jpg";
import seo from "../assets/img/seo.jpg";
import bg_image_1 from "../assets/img/bg_image_1.png";
import bg_image_2 from "../assets/img/bg_image_2.png";
import bg_image_3 from "../assets/img/bg_image_3.png";
import testi_image from "../assets/img/testi_image.png";
import person_1 from "../assets/img/person/person_1.jpg";
import person_2 from "../assets/img/person/person_2.jpg";
import person_3 from "../assets/img/person/person_3.jpg";
import airbnb from "../assets/img/clients/airbnb.png";
import google from "../assets/img/clients/google.png";
import mailchimp from "../assets/img/clients/mailchimp.png";
import paypal from "../assets/img/clients/paypal.png";
import stripe from "../assets/img/clients/stripe.png";
import blog1 from "../assets/img/blog/blog-1.jpg";
import blog2 from "../assets/img/blog/blog-2.jpg";
import blog3 from "../assets/img/blog/blog-3.jpg";
import Header from './Header';
import Footer from './Footer';

const Services = () => {
  return (
    <div>
       <Header page="Services" />
      <main>
        <div className="page-section">
          <div className="container">
            <div className="row">

              <div className="col-lg-12">
                <h2 className="title-section">We're <span className="marked">ready to</span> Serve you with best</h2>
                <div className="divider" />
              </div>

              <div className="col-lg-6 py-3">
                <p className="mb-5"><strong>Web Development:</strong> We specialize in creating professional and responsive websites that are customized to meet your specific needs. Our websites are user-friendly, secure, and optimized for search engines.</p>
              </div>
              <div className="col-lg-6 py-3">
                <div className="img-place text-center">
                  <img src={web_development} alt="" />
                </div>
              </div>

              <div className="col-lg-6 py-3">
                <div className="img-place text-center">
                  <img src={app_development} alt="" />
                </div>
              </div>
              <div className="col-lg-6 py-3">
                <p className="mb-5"><strong>App Development:</strong> We design and develop mobile apps for iOS and Android devices that are engaging, functional, and scalable. We work with you from ideation to launch, ensuring that your app meets your requirements and delivers a great user experience.</p>
              </div>

              <div className="col-lg-6 py-3">
                <p className="mb-5"><strong>SEO:</strong> We help improve your website's visibility and ranking on search engines like Google and Bing. Our SEO strategies are tailored to your business goals, and we use the latest techniques to drive organic traffic to your website.</p>
              </div>
              <div className="col-lg-6 py-3">
                <div className="img-place text-center">
                  <img src={seo} alt="" />
                </div>
              </div>

              <div className="col-lg-6 py-3">
                <div className="img-place text-center">
                  <img src={digital_marketing} alt="" />
                </div>
              </div>
              <div className="col-lg-6 py-3">
                <p className="mb-5"><strong>Digital Marketing:</strong> We offer a range of digital marketing services, including email marketing, social media marketing, and content marketing. We help you create and execute a comprehensive digital marketing strategy that aligns with your business goals.</p>
              </div>

              <div className="col-lg-6 py-3">
                <p className="mb-5"><strong>Facebook Ads:</strong> We create and manage effective Facebook ad campaigns that target your audience and generate leads and conversions. We use advanced targeting and analytics to optimize your ads for maximum ROI.</p>
              </div>
              <div className="col-lg-6 py-3">
                <div className="img-place text-center">
                  <img src={facebook_ads} alt="" />
                </div>
              </div>

              <div className="col-lg-6 py-3">
                <div className="img-place text-center">
                  <img src={google_ads} alt="" />
                </div>
              </div>
              <div className="col-lg-6 py-3">
                <p className="mb-5"><strong>Google Ads:</strong> We help you create and manage Google ad campaigns that appear at the top of search results pages. We use advanced targeting and bidding strategies to help you reach your ideal customers and increase conversions.</p>
              </div>
              <div className="col-lg-6 py-3">
                <p className="mb-5"><strong>Why Choose Us?</strong></p>
                <ul>
                  <li>Experienced professionals with a proven track record</li>
                  <li>Customized solutions tailored to your specific needs</li>
                  <li>Competitive pricing and timely delivery</li>
                  <li>Outstanding customer support and service</li>
                  <li>Commitment to quality and client satisfaction</li>
                </ul>
              </div>


            </div>
          </div> {/* .container */}
        </div> {/* .page-section */}
        <div className="page-section">
          <div className="container">
            <div className="text-center">
              <div className="subhead">Why Choose Us</div>
              <h2 className="title-section">Your <span className="marked">Comfort</span> is Our Priority</h2>
              <div className="divider mx-auto" />
            </div>

            <div className="row mt-5 text-center">
              <div className="col-lg-4 py-3 wow fadeInUp">
                <div className="display-3"><span className="mai-shapes" /></div>
                <h5>High Performance</h5>
                <p>Set clear goals and expectations: Make sure that everyone on the team understands their role and responsibilities, as well as the team's overall objectives.</p>
              </div>
              <div className="col-lg-4 py-3 wow fadeInUp">
                <div className="display-3"><span className="mai-shapes" /></div>
                <h5>Friendly Prices</h5>
                <p>Keep pricing transparent: Be transparent about your pricing and avoid hidden fees or charges. Customers appreciate honesty and clarity.</p>
              </div>
              <div className="col-lg-4 py-3 wow fadeInUp">
                <div className="display-3"><span className="mai-shapes" /></div>
                <h5>No time-confusing</h5>
                <p>Prioritize tasks: Help team members prioritize their tasks by identifying the most important and urgent tasks first. This can help to prevent time-confusion and ensure that deadlines are met.</p>
              </div>
            </div>


          </div> {/* .container */}
        </div> {/* .page-section */}
      </main>
      <Footer />
    </div>
  );
}
export default Services;