

import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import About from './About';
import Contact from './Contact';
import IndexPage from './IndexPage';
import Services from './Services';
import logo from "../assets/img/logo.png";

function NavbarExample(props) {

    var activeHome = props.page == "Home" ? "active" : "";
    var activeAbout = props.page == "About Us" ? "active" : "";
    var activeServices = props.page == "Services" ? "active" : "";
    var activeContact = props.page == "Contact Us" ? "active" : "";
  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <Navbar.Brand href="/" className='d-flex align-items-center'><img
              src={logo}
              width="60"
              height="60"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />&nbsp;&nbsp;Gaud<span className="text-primary " >Tech</span></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="ml-lg-4 pt-3 pt-lg-0"
            style={{ maxHeight: '100%' }}
            navbarScroll
          >
            {/* <Nav.Link href="#action1">Home</Nav.Link> */}
            {/* <Nav.Link href="#action2">Link</Nav.Link> */}
            {/* <NavDropdown title="Link" id="navbarScrollingDropdown">
              <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action4">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">
                Something else here
              </NavDropdown.Item>
            </NavDropdown> */}
            <Nav.Link href="/" className={"nav-link " + activeHome} >
                 Home
            </Nav.Link>
            <Nav.Link href="../about" className={"nav-link " + activeAbout} >
                 About
            </Nav.Link>
            <Nav.Link href="../services" className={"nav-link " + activeServices} >
                 Services
            </Nav.Link>
            <Nav.Link href="../contact" className={"nav-link " + activeContact} >
            Contact
            </Nav.Link>
          </Nav>
          {/* <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
            <Button variant="outline-success">Search</Button>
          </Form> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarExample;
