
import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import NavbarExample from './Navbar';


const Header = (props) => {
    return (<div>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="copyright" content="Gaud Tech Solutions https://gaudtech.com" />
        <title>Gaud Tech Solutions</title>
        <div className="back-to-top" />
        <header>
            <NavbarExample page={props.page}/>

            <div className="container mt-5">
                <div className="page-banner">
                    <div className="row justify-content-center align-items-center h-100">
                        <div className="col-md-6">
                            <nav aria-label="Breadcrumb">
                                <ul className="breadcrumb justify-content-center py-0 bg-transparent">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active">{props.page}</li>
                                </ul>
                            </nav>
                            <h1 className="text-center">{props.page}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </header>,
    </div>
    );
}
export default Header;
