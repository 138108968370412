import React from 'react';
import { Link } from "react-router-dom";
// import myImage from './myImage.jpg';
import web_development from "../assets/img/web_development.jpg";
import bg_image_1 from "../assets/img/bg_image_1.png";
import bg_image_2 from "../assets/img/bg_image_2.png";
import bg_image_3 from "../assets/img/bg_image_3.png";
import testi_image from  "../assets/img/testi_image.png";
import person_1 from "../assets/img/person/person_1.jpg";
import person_2 from "../assets/img/person/person_2.jpg";
import person_3 from "../assets/img/person/person_3.jpg";
import airbnb from "../assets/img/clients/airbnb.png";
import google from "../assets/img/clients/google.png";
import mailchimp from "../assets/img/clients/mailchimp.png";
import paypal from "../assets/img/clients/paypal.png";
import stripe from "../assets/img/clients/stripe.png";
import blog1 from "../assets/img/blog/blog-1.jpg";
import blog2 from "../assets/img/blog/blog-2.jpg";
import blog3 from "../assets/img/blog/blog-3.jpg";
import Header from './Header';
import Footer from './Footer';
const IndexPage = () => {
  return (
    <div>
      <Header page="Home"/>
      <main>
        <div className="page-section features">
          <div className="container">
            {/* <div className="row justify-content-center">
              <div className="col-md-6 col-lg-4 py-3 wow fadeInUp">
                <div className="d-flex flex-row">
                  <div className="img-fluid mr-3">
                    <img src="../assets/img/icon_pattern.svg" alt="" />
                  </div>
                  <div>
                    <h5>Provide financial advice by our advisor</h5>
                    <p>Copywrite, blogpublic realations content translation.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 py-3 wow fadeInUp">
                <div className="d-flex flex-row">
                  <div className="img-fluid mr-3">
                    <img src="../assets/img/icon_pattern.svg" alt="" />
                  </div>
                  <div>
                    <h5>Complete solutions for global organisations</h5>
                    <p>Copywrite, blogpublic realations content translation.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 py-3 wow fadeInUp">
                <div className="d-flex flex-row">
                  <div className="img-fluid mr-3">
                    <img src="../assets/img/icon_pattern.svg" alt="" />
                  </div>
                  <div>
                    <h5>Provide financial advice by our advisor</h5>
                    <p>Copywrite, blogpublic realations content translation.</p>
                  </div>
                </div>
              </div>
            </div> */}
          </div> {/* .container */}
        </div> {/* .page-section */}
        <div className="page-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 py-3 wow zoomIn">
                <div className="img-place text-center">
                  <img src={bg_image_2} alt="" />
                </div>
              </div>
              <div className="col-lg-6 py-3 wow fadeInRight">
                <h2 className="title-section">We're <span className="marked">Dynamic</span> Team of Creatives People</h2>
                <div className="divider" />
                <p>We provide marketing services to startups &amp; small business to looking for partner for their digital media, design &amp; dev lead generation &amp; communication.</p>
                <div className="img-place mb-3">
                  <img src={testi_image} alt="" />
                </div>
                {/* <a href="#" className="btn btn-primary">More Details</a> */}
                {/* <a href="#" className="btn btn-outline border ml-2">Success Stories</a> */}
              </div>
            </div>
          </div> {/* .container */}
        </div> {/* .page-section */}
        {/* <div className="page-section counter-section"> */}
          {/* <div className="container">
            <div className="row align-items-center text-center">
              <div className="col-lg-4">
                <p>Total Invest</p>
                <h2>$<span className="number" data-number={816278} /></h2>
              </div>
              <div className="col-lg-4">
                <p>Yearly Revenue</p>
                <h2>$<span className="number" data-number={216422} /></h2>
              </div>
              <div className="col-lg-4">
                <p>Growth Ration</p>
                <h2><span className="number" data-number={73} />%</h2>
              </div>
            </div>
          </div> */}
           {/* .container */}
        {/* </div>  */}
        {/* .page-section */}
        <div className="page-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 py-3 wow fadeInLeft">
                <h2 className="title-section">We're <span className="marked">ready to</span> Serve you with best</h2>
                <div className="divider" />
                <p className="mb-5">We provide marketing services to startups &amp; small business to looking for partner for their digital media, design &amp; dev lead generation &amp; communication.</p>
                {/* <a href="#" className="btn btn-primary">More Details</a> */}
                {/* <a href="#" className="btn btn-outline ml-2">See pricing</a> */}
              </div>
              <div className="col-lg-6 py-3 wow zoomIn">
                <div className="img-place text-center">
                  <img src={bg_image_3} alt="" />
                </div>
              </div>
            </div>
          </div> {/* .container */}
        </div> {/* .page-section */}
        <div className="page-section">
          <div className="container">
            <div className="text-center wow fadeInUp">
              <div className="subhead">Why Choose Us</div>
              <h2 className="title-section">Your <span className="marked">Comfort</span> is Our Priority</h2>
              <div className="divider mx-auto" />
            </div>
            <div className="row mt-5 text-center">
              <div className="col-lg-4 py-3 wow fadeInUp">
                <div className="display-3"><span className="mai-shapes" /></div>
                <h5>High Performance</h5>
                <p>Set clear goals and expectations: Make sure that everyone on the team understands their role and responsibilities, as well as the team's overall objectives.</p>
              </div>
              <div className="col-lg-4 py-3 wow fadeInUp">
                <div className="display-3"><span className="mai-shapes" /></div>
                <h5>Friendly Prices</h5>
                <p>Keep pricing transparent: Be transparent about your pricing and avoid hidden fees or charges. Customers appreciate honesty and clarity.</p>
              </div>
              <div className="col-lg-4 py-3 wow fadeInUp">
                <div className="display-3"><span className="mai-shapes" /></div>
                <h5>No time-confusing</h5>
                <p>Prioritize tasks: Help team members prioritize their tasks by identifying the most important and urgent tasks first. This can help to prevent time-confusion and ensure that deadlines are met.</p>
              </div>
            </div>
          </div> {/* .container */}
        </div> {/* .page-section */}
        <div className="page-section border-top">
          {/* <div className="container">
            <div className="text-center wow fadeInUp">
              <h2 className="title-section">Pricing Plan</h2>
              <div className="divider mx-auto" />
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-lg-auto py-3 wow fadeInLeft">
                <div className="card-pricing">
                  <div className="header">
                    <div className="price-icon"><span className="mai-people" /></div>
                    <div className="price-title">Membership</div>
                  </div>
                  <div className="body py-3">
                    <div className="price-tag">
                      <span className="currency">$</span>
                      <h2 className="display-4">30</h2>
                      <span className="period">/monthly</span>
                    </div>
                    <div className="price-info">
                      <p>Choose the plan that right for you</p>
                    </div>
                  </div>
                  <div className="footer">
                    <a href="#" className="btn btn-outline rounded-pill">Choose Plan</a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-auto py-3 wow fadeInUp">
                <div className="card-pricing active">
                  <div className="header">
                    <div className="price-labled">Best</div>
                    <div className="price-icon"><span className="mai-business" /></div>
                    <div className="price-title">Dedicated</div>
                  </div>
                  <div className="body py-3">
                    <div className="price-tag">
                      <span className="currency">$</span>
                      <h2 className="display-4">60</h2>
                      <span className="period">/monthly</span>
                    </div>
                    <div className="price-info">
                      <p>Choose the plan that right for you</p>
                    </div>
                  </div>
                  <div className="footer">
                    <a href="#" className="btn btn-outline rounded-pill">Choose Plan</a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-auto py-3 wow fadeInRight">
                <div className="card-pricing">
                  <div className="header">
                    <div className="price-icon"><span className="mai-rocket-outline" /></div>
                    <div className="price-title">Private</div>
                  </div>
                  <div className="body py-3">
                    <div className="price-tag">
                      <span className="currency">$</span>
                      <h2 className="display-4">90</h2>
                      <span className="period">/monthly</span>
                    </div>
                    <div className="price-info">
                      <p>Choose the plan that right for you</p>
                    </div>
                  </div>
                  <div className="footer">
                    <a href="#" className="btn btn-outline rounded-pill">Choose Plan</a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
           {/* .container */}
        </div> {/* .page-section */}
        <div className="page-section bg-light">
          <div className="container">
            <div className="owl-carousel wow fadeInUp" id="testimonials">
              <div className="item">
                <div className="row align-items-center">
                  <div className="col-md-6 py-3">
                    <div className="testi-image">
                      <img src={person_1} alt="" />
                    </div>
                  </div>
                  {/* <div className="col-md-6 py-3">
                    <div className="testi-content">
                      <p>Necessitatibus ipsum magni accusantium consequatur delectus a repudiandae nemo quisquam dolorum itaque, tenetur, esse optio eveniet beatae explicabo sapiente quo.</p>
                      <div className="entry-footer">
                        <strong>Melvin Platje</strong> — <span className="text-grey">CEO Slurin Group</span>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="item">
                <div className="row align-items-center">
                  <div className="col-md-6 py-3">
                    <div className="testi-image">
                      <img src={person_2} alt="" />
                    </div>
                  </div>
                  {/* <div className="col-md-6 py-3">
                    <div className="testi-content">
                      <p>Repudiandae vero assumenda sequi labore ipsum eos ducimus provident a nam vitae et, dolorum temporibus inventore quaerat consectetur quos! Animi, qui ratione?</p>
                      <div className="entry-footer">
                        <strong>George Burke</strong> — <span className="text-grey">CEO Letro</span>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div> {/* .container */}
        </div> {/* .page-section */}
        {/* <div className="page-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 py-3 wow fadeInUp">
                <h2 className="title-section">Get in Touch</h2>
                <div className="divider" />
                <p>We'd love to hear from you! If you have any questions, comments, or inquiries, please don't hesitate to contact us. Here are the different ways you can get in touch with us:</p>
                <ul className="contact-list">
                  <li>
                    <div className="icon"><span className="mai-map" /></div>
                    <div className="content">Namdev Plaza, Badlapur, Thane, Maharashtra - 421503 </div>
                  </li>
                  <li>
                    <div className="icon"><span className="mai-mail" /></div>
                    <div className="content"><a href="#">info@gaudtech.com</a></div>
                  </li>
                  <li>
                    <div className="icon"><span className="mai-phone-portrait" /></div>
                    <div className="content"><a href="#">+91 9137387575</a></div>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 py-3 wow fadeInUp">
                <div className="subhead">Contact Us</div>
                <h2 className="title-section">Drop Us a Line</h2>
                <div className="divider" />
                <form action="#">
                  <div className="py-2">
                    <input type="text" className="form-control" placeholder="Full name" />
                  </div>
                  <div className="py-2">
                    <input type="text" className="form-control" placeholder="Email" />
                  </div>
                  <div className="py-2">
                    <textarea rows={6} className="form-control" placeholder="Enter message" defaultValue={""} />
                  </div>
                  <button type="submit" className="btn btn-primary rounded-pill mt-4">Send Message</button>
                </form>
              </div>
            </div>
          </div>  */}
          {/* .container */}
        {/* </div>  */}
        {/* .page-section */}
        {/* <div className="page-section border-top">
          <div className="container">
            <div className="text-center wow fadeInUp">
              <div className="subhead">Our Blog</div>
              <h2 className="title-section">Read our latest <span className="marked">News</span></h2>
              <div className="divider mx-auto" />
            </div>
            <div className="row my-5 card-blog-row">
              <div className="col-md-6 col-lg-3 py-3 wow fadeInUp">
                <div className="card-blog">
                  <div className="header">
                    <div className="entry-footer">
                      <div className="post-author">Sam Newman</div>
                      <a href="#" className="post-date">23 Apr 2020</a>
                    </div>
                  </div>
                  <div className="body">
                    <div className="post-title"><a href="blog-single.html">What is Business Management?</a></div>
                  </div>
                  <div className="footer">
                    <a href="blog-single.html">Read More <span className="mai-chevron-forward text-sm" /></a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 py-3 wow fadeInUp">
                <div className="card-blog">
                  <div className="header">
                    <div className="avatar">
                      <img src={person_1} alt="" />
                    </div>
                    <div className="entry-footer">
                      <div className="post-author">Sam Newman</div>
                      <a href="#" className="post-date">23 Apr 2020</a>
                    </div>
                  </div>
                  <div className="body">
                    <div className="post-title"><a href="blog-single.html">What is Business Management?</a></div>
                    <div className="post-excerpt">Lorem, ipsum dolor sit amet consectetur adipisicing elit.</div>
                  </div>
                  <div className="footer">
                    <a href="blog-single.html">Read More <span className="mai-chevron-forward text-sm" /></a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 py-3 wow fadeInUp">
                <div className="card-blog">
                  <div className="header">
                    <div className="avatar">
                      <img src={person_2} alt="" />
                    </div>
                    <div className="entry-footer">
                      <div className="post-author">Sam Newman</div>
                      <a href="#" className="post-date">23 Apr 2020</a>
                    </div>
                  </div>
                  <div className="body">
                    <div className="post-title"><a href="blog-single.html">What is Business Management?</a></div>
                    <div className="post-excerpt">Lorem, ipsum dolor sit amet consectetur adipisicing elit.</div>
                  </div>
                  <div className="footer">
                    <a href="blog-single.html">Read More <span className="mai-chevron-forward text-sm" /></a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 py-3 wow fadeInUp">
                <div className="card-blog">
                  <div className="header">
                    <div className="avatar">
                      <img src={person_3} alt="" />
                    </div>
                    <div className="entry-footer">
                      <div className="post-author">Sam Newman</div>
                      <a href="#" className="post-date">23 Apr 2020</a>
                    </div>
                  </div>
                  <div className="body">
                    <div className="post-title"><a href="blog-single.html">What is Business Management?</a></div>
                    <div className="post-excerpt">Lorem, ipsum dolor sit amet consectetur adipisicing elit.</div>
                  </div>
                  <div className="footer">
                    <a href="blog-single.html">Read More <span className="mai-chevron-forward text-sm" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <a href="blog.html" className="btn btn-outline-primary rounded-pill">Discover More</a>
            </div>
          </div> */}
           {/* .container */}
        {/* </div>  */}
        {/* .page-section */}
      </main>
      <Footer />
    </div>
  );
}
export default IndexPage;